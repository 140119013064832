import { ChevronRight, Construction } from '@mui/icons-material';
import { Alert, AlertTitle, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { paths } from '../../routes/paths';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Container } from '@mui/system';
import { MaintenanceCreateDTO } from '@rotra-air-ocean/rotranext-shared-code';
import { usePathname } from '../../routes/hooks';

const POLLING_INTERVAL_MS = 30000;

const getMaintenanceLocation = () =>
  `${process.env.VITE_MYROTRA_CDN || ''}maintenance-test.json`;

const useMaintenanceStatus = (
  cacheBusterString: string,
): boolean | undefined => {
  const [inMaintenance, setInMaintenance] = useState<boolean | undefined>(
    false,
  );

  const fetchMaintenance = useCallback(() => {
    fetch(getMaintenanceLocation() + `?cacheBuster=${cacheBusterString}`, {
      cache: 'no-cache',
    })
      .then((response) => response.json() as Promise<MaintenanceCreateDTO>)
      .then((data) => setInMaintenance(data.inMaintenance))
      .catch(() => setInMaintenance(false));
  }, [cacheBusterString]);

  useEffect(() => {
    fetchMaintenance();
  }, [fetchMaintenance]);

  return inMaintenance;
};

const MaintenanceModeAlert = () => {
  const pathname = usePathname();
  const [cacheBusterDate, setCacheBusterDate] = useState<DateTime>(
    DateTime.utc(),
  );

  const cacheBusterString = useMemo(
    () => cacheBusterDate.set({ second: 0 }).toSeconds().toString(),
    [cacheBusterDate],
  );

  const inMaintenance = useMaintenanceStatus(cacheBusterString);

  useEffect(() => {
    const interval = setInterval(
      () => setCacheBusterDate(DateTime.utc()),
      POLLING_INTERVAL_MS,
    );
    return () => clearInterval(interval);
  }, []);

  if (!inMaintenance || pathname === paths.dashboard.maintenance.root)
    return null;

  return (
    <Container>
      <Paper>
        <Alert
          icon={<Construction />}
          severity="warning"
          action={
            <Button
              color="primary"
              size="small"
              variant="outlined"
              endIcon={<ChevronRight />}
              component={Link}
              to={paths.dashboard.maintenance.root}
            >
              Learn more
            </Button>
          }
        >
          <AlertTitle>myRotra is currently in maintenance mode</AlertTitle>
        </Alert>
      </Paper>
    </Container>
  );
};

export default MaintenanceModeAlert;
